<template>
    <validation-observer ref="simpleRules">
        <div class="text-center">
            <h1 class="font-weight-bolder">Submit a New Case</h1>
        </div>

        <div>
            <div class="accordion-title">
                <h3>Client / IR</h3>
            </div>
            <b-row class="pb-2 pt-2">
                <b-col>
                    <ClientInfo
                            @changeClientInfo="changeClientInfo"
                    />
                </b-col>
            </b-row>
        </div>

        <div>
            <div class="accordion-title">
                <h3>Assignment Information</h3>
            </div>
            <b-row>
                <b-col>
                    <AssignmentInformation
                            @changeAssignment="changeAssignment"
                    />
                </b-col>
            </b-row>
        </div>

        <div>
            <div class="accordion-title">
                <h3>Claim Details</h3>
            </div>
            <b-row>
                <b-col>
                    <ClaimDetails @changeClaimDetail="changeClaimDetail" />
                </b-col>
            </b-row>
        </div>

        <div>
            <div class="accordion-title">
                <h3>Case Notes</h3>
            </div>
            <b-row>
                <b-col>
                    <CaseNotes
                            @changedSurveillanceConducted="changedSurveillanceConducted"
                    />
                </b-col>
            </b-row>
        </div>

        <div>
            <div class="accordion-title">
                <h3>Claimant Details (Subject / EE / Injured Worker)</h3>
            </div>
            <b-row>
                <b-col>
                    <SubjectDetails
                            @changeSubject="changeSubject"
                    />
                    <SubjectAddress
                            @changeSubjectAddress="changeSubjectAddress"
                    />
                    <SubjectDescriptionPublic
                            @changedSubjectDescription="changedSubjectDescription"
                    />
                </b-col>
            </b-row>
        </div>

        <div>
            <b-row>
                <b-col>
                    <b-form-group
                            class="pl-1"
                            label="Include up to 5 files, 10 MB total size."
                    >
                        <FileUploaderPublic
                                @uploadFiles="getFiles"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <b-row class="mt-2">
          <b-col>
            <vue-recaptcha
              class="d-flex justify-content-center"
              :sitekey="siteKey"
              @verify="recaptchaVerify"
              @expired="recaptchaExpired"
            />
          </b-col>
        </b-row>

        <b-row class="pb-2 pt-2 mt-2">
            <b-col>
                <div class="d-flex justify-content-center">
                    <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="success"
                            :disabled="isLoading"
                            @click="submitCase()"
                    >
                        Click to Submit Assignment
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <div v-if="CaseSubmitted">
            <h2><strong>Case submitted!</strong></h2>
        </div>
    </validation-observer>
</template>

<script>
import {BRow, BCol, BButton, BFormGroup} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-good-table/dist/vue-good-table.css";
import { required } from "@validations";
import { mapGetters, mapMutations } from "vuex";
import {ValidationObserver} from "vee-validate";
import AssignmentInformation from "@/views/cases/public/AssignmentInformation";
import ClaimDetails from "@/views/cases/public/ClaimDetails";
import CaseNotes from "@/views/cases/public/CaseNotes";
import SubjectDetails from "@/views/cases/public/SubjectDetails";
import SubjectDescriptionPublic from "@/views/cases/public/SubjectDescriptionPublic";
import FileUploaderPublic from "@/views/cases/public/FileUploaderPublic";
import SubjectAddress from "@/views/cases/public/SubjectAddress";
import ClientInfo from "@/views/cases/public/ClientInfo";
import {VueRecaptcha} from "vue-recaptcha";
import axios from "axios";
import {secret, sitekey} from '@/config/config'

const axiosInc = axios;

export default {
    components: {
        ClientInfo,
        SubjectAddress,
        FileUploaderPublic,
        SubjectDescriptionPublic,
        CaseNotes,
        ValidationObserver,
        SubjectDetails,
        ClaimDetails,
        AssignmentInformation,
        BFormGroup,
        BRow,
        BCol,
        BButton,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent,
        VueRecaptcha,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            caseObject: {},
            Subject: {},
            SubjectAddress: {},
            CaseSubmitted: false,
            isLoading: true,
            required,
            uploadFiles: [],
            siteKey: sitekey,
        }
    },
    computed: {
        ...mapGetters({
            getCase: "publicCase/getCase",
            getSubject: "publicCase/getSubject",
            getSubjectAddress: "publicCase/getSubjectAddress",
        }),
    },
    async created() {
        this.emptyCase();
        this.caseObject = this.getCase;
    },
    methods: {
        ...mapMutations({
            setCase: "publicCase/setCase",
            setSubject: "publicCase/setSubject",
            setSubjectAddress: "publicCase/setSubjectAddress",
            emptyCase: "publicCase/emptyCase",
        }),
        showToast(variant, position, timeout, data) {
            this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'InfoIcon',
                        text: data,
                        variant,
                    },
                },
                {
                    position,
                    timeout,
                })
        },

        recaptchaVerify(res) {
            if (res) {
                axiosInc.post("/api/recaptcha?token=" + res)
                    .then(result => {
                        if (result.data && result.data.success) {
                            this.isLoading = false;
                        } else {
                            this.showToast('danger', 'top-center', 4000, 'Not Verified!');
                        }
                    })
            }
        },

        recaptchaExpired() {
            this.isLoading = true;
        },

        submitCase() {
            this.isLoading = true;
            let postData = {
                ClientName: this.caseObject.ClientName,
                RequesterName: this.caseObject.RequesterName,
                RequesterEmail: this.caseObject.RequesterEmail,
                RequesterPhone: this.caseObject.RequesterPhone,
                CcEmails: this.caseObject.CcEmails,

                ClaimFileNo: this.caseObject.ClaimFileNo,
                ClaimFileType: this.caseObject.ClaimFileType,
                InsuredText: this.caseObject.InsuredText,
                InsuredContactInformation: this.caseObject.InsuredContact,
                InsuredRepresentative: this.caseObject.InsuredRepresentative,
                ClaimHandler: this.caseObject.ClaimHandler,
                IsContactByAccountManager: this.caseObject.IsContactByAccountManager,
                IsPreferPhysicalMedia: this.caseObject.IsPreferPhysicalMedia,
                RequestedServices: this.caseObject.RequestedServices,
                RequestedServicesOther: this.caseObject.RequestedServicesOther,
                BudgetHours: this.caseObject.BudgetHours,
                BudgetDollars: this.caseObject.BudgetDollars,
                CaseDueDate: this.formattedDate(this.caseObject.CaseDueDate),

                InjuryDate: this.formattedDate(this.caseObject.InjuryDate),
                InjuryDesc: this.caseObject.InjuryDesc,
                MedicalRestrictions: this.caseObject.MedicalRestrictions,
                RedFlags: this.caseObject.RedFlags,
                EmploymentInfoWorkStatus: this.caseObject.EmploymentInfoWorkStatus,
                HasAttorneyRepresentation: this.caseObject.HasAttorneyRepresentation,
                AttorneyRepInfo: this.caseObject.AttorneyRepInfo,
                ScheduledAppts: this.caseObject.ScheduledAppts,
                ScheduledApptWhen: this.caseObject.ScheduledApptWhen,
                ScheduledApptWhere: this.caseObject.ScheduledApptWhere,

                HasPreviousSurveillanceConducted: this.caseObject.HasPreviousSurveillanceConducted,
                PreviousSurveillanceConductedBy: this.caseObject.PreviousSurveillanceConductedBy,
                PreviousSurveillanceResults: this.caseObject.PreviousSurveillanceResults,
                ReasonForSurveillance: this.caseObject.ReasonForSurveillance,
                NotesRegardingThisAssignment: this.caseObject.NotesRegardingThisAssignment,

                FilePermissions: [],

                Subject: {
                    FirstName: this.Subject.FirstName,
                    MiddleName: this.Subject.MiddleName,
                    LastName: this.Subject.LastName,
                    Alias: this.Subject.Alias,
                    SocialSecurityNo: this.Subject.SocialSecurityNo,
                    DateOfBirth: this.formattedDate(this.Subject.DateOfBirth),
                    DriversLicenseNo: this.Subject.DriversLicenseNo,
                    DriversLicenseState: this.Subject.DriversLicenseState,
                    Phone1: this.Subject.Phone1,
                    Phone1Type: this.Subject.Phone1Type,
                    Phone2: this.Subject.Phone2,
                    Phone2Type: this.Subject.Phone2Type,

                    Addresses: this.SubjectAddress,

                    Gender: this.Subject.Gender,
                    Height: this.Subject.Height,
                    Weight: this.Subject.Weight,
                    Spouse: this.Subject.Spouse,
                    Children: this.Subject.Children,
                    Race: this.Subject.Race,
                    Hair: this.Subject.Hair,
                    HairStyle: this.Subject.HairStyle,
                    Eyes: this.Subject.Eyes,
                    Glasses: this.Subject.Glasses,
                    OtherCharacteristics: this.Subject.OtherCharacteristics,
                    VehicleInformation: this.Subject.VehicleInformation,
                },
            };

            this.uploadFiles.map(item => {
                postData.FilePermissions.push(item.data)
            })

            if (this.validateToSave()) {
                let formData = new FormData();
                const documentJson = JSON.stringify(postData)
                this.uploadFiles.map(item => {
                    formData.append("files", item.file.file);
                })
                formData.append("data", documentJson);
                axiosInc
                    .post('/api/v2/public/case', formData, {'Content-Type': 'multipart/form-data'})
                    .then(response => {
                        if (response) {
                            this.showToast('success', 'top-center', 4000, 'Your case has been submitted!');
                            this.CaseSubmitted = true;
                        } else {
                            this.isLoading = false;
                        }
                    });
            } else {
                this.isLoading = false;
            }
        },
        validateToSave() {
            let errorMsg = "";
            errorMsg = this.getCase.ClientName ? "" : "Your Company Name, ";
            errorMsg = this.getCase.RequesterName ? errorMsg + "" : errorMsg + "Your Name, ";
            errorMsg = this.getCase.RequesterEmail ? errorMsg + "" : errorMsg + "Your Email, ";
            errorMsg = this.getSubject.FirstName ? errorMsg + "" : errorMsg + "Your Subject First Name, ";
            errorMsg = this.getSubject.LastName ? errorMsg + "" : errorMsg + "Your Subject Last Name, ";
            errorMsg = this.getSubjectAddress.Address1 ? errorMsg + "" : errorMsg + "Your Subject Address, ";
            errorMsg = this.getSubjectAddress.City1 ? errorMsg + "" : errorMsg + "Your Subject City, ";
            errorMsg = this.getSubjectAddress.StateProvince1 ? errorMsg + "" : errorMsg + "Your Subject State, ";
            errorMsg = this.getSubjectAddress.PostalCode1 ? errorMsg + "" : errorMsg + "Your Subject Zip, ";
            if (errorMsg) {
                this.showToast('danger', 'top-center', 4000, JSON.stringify('Please provide all required fields: ' + errorMsg))
            }
            return !errorMsg;
        },
        formattedDate(date) {
            if (date) {
                date = new Date(date)
                const TimezoneOffset = new Date().getTimezoneOffset() / 60
                date.setHours(date.getHours() + TimezoneOffset)
                const year = date.getFullYear();
                let month = (1 + date.getMonth()).toString();
                month = month.length > 1 ? month : '0' + month;
                let day = date.getDate().toString();
                day = day.length > 1 ? day : '0' + day;
                return month + '/' + day + '/' + year;
            }
            return null;
        },
        changeClientInfo(val) {
            const subject = {...this.getSubject, SubjectAddress: this.getSubjectAddress}
            this.caseObject = {...this.caseObject, ...val, Subject: subject};
            this.setCase(this.caseObject);
        },
        changeAssignment(val) {
            const subject = {...this.getSubject, SubjectAddress: this.getSubjectAddress}
            this.caseObject = {...this.caseObject, ...val, Subject: subject};
            this.setCase(this.caseObject);
        },
        changeClaimDetail(val) {
            const ScheduledApptWhen = val.Appointments[0].ScheduledApptWhen;
            const ScheduledApptWhere = val.Appointments[0].ScheduledApptWhere;
            const subject = {...this.getSubject, SubjectAddress: this.getSubjectAddress}
            this.caseObject = {...this.caseObject, ...val, ScheduledApptWhen, ScheduledApptWhere,  Subject: subject};
            this.setCase(this.caseObject);
        },
        changedSurveillanceConducted(val) {
            const subject = {...this.getSubject, SubjectAddress: this.getSubjectAddress}
            this.caseObject = {...this.caseObject, ...val, Subject: subject};
            this.setCase(this.caseObject);
        },
        changeSubject(val) {
            this.Subject = {...this.Subject, ...val};
            this.setSubject(this.Subject);
        },
        changeSubjectAddress(val) {
            this.SubjectAddress = {...this.SubjectAddress, ...val};
            this.setSubjectAddress(this.SubjectAddress);
        },
        changedSubjectDescription(val) {
            this.Subject = {...this.Subject, ...val};
            this.setSubject(this.Subject);
        },
        getFiles(val) {
            this.uploadFiles = val;
        },
    },
}
</script>

<style>
hr {
    border: 1px solid #ebe9f1;
}
.accordion-title h3 {
    color: white;
    font-weight: bold;
    margin: 0.5rem;
}
.accordion-title {
    background: #476dae;
    display: flex;
    align-items: center;
}
</style>
